<template>
  <div
    id="carouselExampleSlidesOnly"
    class="carousel slide"
    data-bs-ride="carousel"
  >
    <div class="carousel-inner">
      <div
        :class="item.active"
        data-bs-interval="5000"
        v-for="item in GetSliderImageData"
        :key="item"
      >
        <img :src="item.src" class="img img-fluid slider-image" alt="slide_image" />
        <div class="carousel-caption d-md-block d-none p-0" v-if="item.author != undefined">
          <p class="mobile">Foto: <span class="fw-bold mobile">{{item.author}}</span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SlideshowWidget",
  props: {
      GetSliderImageData: Object
  }
};
</script>

<style scoped>
@media (max-width: 576px) {
  .mobile {
    font-size: 11px;
  }
}
</style>