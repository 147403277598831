export let News = [
    {
        id: 1,
        title: `Bo letošnje poletje vroče in suho?`,
        content: `Končuje se najbolj vroč dan v letošnjem letu do zdaj. Na Ribniškem in Kočevskem se je živo srebro ustavilo tik pod tridesetico. Zelo topla zračna masa bo nad nami vztrajala tudi ob koncu tega in v začetku prihodnjega tedna, le občasno bodo ozračje osvežile krajevne plohe in nevihte, ki pa bodo na Ribniškem in Kočevskem manj verjetne.&
        Kot vse kaže, boste tudi v prihodnjih mesecih na svoj račun prišli vsi ljubitelji sonca in visokih temperatur. Po napovedi evropskega meteorološkega centra ECMWF naj bi bilo namreč letošnje poletje dolgo, vroče in zelo suho. Najtoplejši zrak se bo po tej napovedi zadrževal ravno v bližini naših krajev, kjer bodo temperature presegle povprečje zadnjih tridesetih let za okoli dve stopinji, kar bi nas močno približalo doslej najbolj vročemu poletju iz leta 2003.&
        Letošnje poletje naj bi nad večjim delom stare celine zaznamovalo tudi veliko pomanjkanje padavin, to pa bi lahko še dodatno okrepilo vročino. Suha tla se namreč precej bolj segrejejo kot mokra. V preteklosti smo najvišje temperature izmerili ravno ob dalj časa trajajočemu suhemu vremenu.&`,
        day: `20`,
        month: `maj`,
        imgUrl: `https://postaje.vreme-podnebje.si/images/news/termometer.jpeg`,
        modalImgUrl: null
    },
    {
        id: 2,
        title: `Zelo sveže jutro`,
        content: `Današnje jutro je bilo za letošnji maj neobičajno sveže. Še posebej hladno je bilo v mraziščih južne Slovenije, kjer se je temperatura lokalno spustila celo pod ničlo. V Retjah v Loškem Potoku je termometer ob šesti uri zjutraj pokazal -1,2 stopinje Celzija.&
        Dopoldne bosta sonce in šibak južni veter živo srebro hitro potiskala navzgor in popoldne bo v spet poletno toplo s temperaturami okoli 25 stopinj Celzija.&
        Še bolj vroč zrak bo k nam dotekal jutri in v soboto, ko se bomo v vzhodnem delu Slovenije močno približali majskim rekordom. Najvišje temperature bodo po najnižjih delih dolin in kotlin segle do 32 ali 33 °C, predvsem v Beli krajini in Posavju pa bo lokalno lahko še kakšna stopinja več. Tudi na Ribniškem in Kočevskem bomo morda v soboto že izmerili prvo letošnjo tridesetico.&
        Kot vse kaže, bo zelo toplo vreme z manjšimi nihanji vztrajalo tudi v prihodnjem tednu, le občasno bodo ozračje osvežile krajevne plohe in nevihte.&`,
        day: `19`,
        month: `maj`,
        imgUrl: `https://postaje.vreme-podnebje.si/images/news/cerkev.jpg`,
        modalImgUrl: null
    },
    {
        id: 3,
        title: `Obiskalo nas bo zgodnje poletje`,
        content: `Po daljšem obdobju zmerno toplega vremena, ko smo se vsak dan srečevali z možnostjo nastanka krajevnih ploh in neviht, nas bo v prihodnjih dneh že obiskalo zgodnje poletje. Najtoplejši dan v tem tednu bo četrtek, ko bo šibak jugozahodni veter nižinam vzhodne Slovenije zelo verjetno že prinesel prvo letošnjo tridesetico. Na Ribniškem in Kočevskem bodo najvišje temperature okoli 27 stopinj Celzija.&
        V petek se nam bo bližala vremenska fronta. Na jugu bo večji del dneva še sončno in zelo toplo, na severu pa bodo ozračje popoldne že osvežile krajevne plohe in nevihte, ki bodo uvod v nekoliko bolj spremenljiv in za spoznanje manj topel konec tedna. Temperature bodo v povprečju padle za okoli pet stopinj Celzija. A dobra novica za vse ljubitelje sonca in visokih temperatur je ta, da bo že v prihodnjem tednu vreme spet bolj stabilno.&`,
        day: `10`,
        month: `maj`,
        imgUrl: `https://postaje.vreme-podnebje.si/images/news/retjeNaslovna.jpg`,
        modalImgUrl: null
    },
]

export default News;